import React, { useEffect } from 'react';
import {
  createMuiTheme,
  makeStyles,
  ThemeProvider as MuiThemeProvider
} from '@material-ui/core/styles';
import { ThemeProvider } from 'styled-components';
import CssBaseline from '@material-ui/core/CssBaseline';
// import '../../assets/css/IndexPanel.css';
import MensajeError from '../Layout/Messages/MensajeError';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import tenantReset from '../../reset/tenantReset';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { fetchTenant } from '../../actions/TenantActions';
import { Route, Switch } from 'react-router-dom';
import RestablecerOlvideMiPassword from '../Login/RestablecerOlvideMiPassword';
import SolicitudOlvideMiPassword from '../Login/SolicitudOlvideMiPassword';
import Login from '../Login/Login';
import BriefSignUp from '../SignUp/BriefSignUp';
import logo from '../../assets/img/logo-glassprop.svg';
import { Box } from '@material-ui/core';

//Actions
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://exadec.com/">
        Glassprop
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  offset: theme.mixins.toolbar,
  root: {
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      display: 'flex'
    },
    paddingTop: theme.spacing(3),
    minHeight: 'calc(100vh - 110px)'
  },
  principal: {
    flexGrow: 1,
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0)
    }
  },
  footer: {
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap'
  }
}));
export default function LayoutKit(props) {
  const [darked, setDarked] = React.useState(() => {
    // return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    'light';
  });
  const [open, setOpen] = React.useState(true);
  const dispatch = useDispatch();
  let { idCuenta } = useParams();

  const tenants = useSelector(state => state.tenants);
  const tenant = idCuenta ? tenants.byId.tenants[idCuenta] : null;

  //Effects
  useEffect(() => {
    let themeColor = document.getElementById('themeColor');
    if (themeColor)
      themeColor.content = theme.palette.primary[theme.palette.type];
    document.title = 'glassprop | Dashboard';
  }, []);

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const toggleTypeTheme = () => {
    setDarked(prev => !prev);
  };

  const theme = createMuiTheme({
    palette: {
      primary: {
        light:
          tenant && tenant.colorPrincipal ? tenant.colorPrincipal : '#865DFF',
        main:
          tenant && tenant.colorPrincipal ? tenant.colorPrincipal : '#865DFF',
        dark:
          tenant && tenant.colorPrincipal ? tenant.colorPrincipal : '#865DFF',
        contrastText: '#fff'
      },
      secondary: {
        light:
          tenant && tenant.colorSecundario ? tenant.colorSecundario : '#E384FF',
        main:
          tenant && tenant.colorSecundario ? tenant.colorSecundario : '#E384FF',
        dark:
          tenant && tenant.colorSecundario ? tenant.colorSecundario : '#E384FF',
        contrastText: '#000'
      },
      success: {
        light: '#aed581',
        main: '#4caf50',
        dark: '#80936a'
      },
      error: {
        main: '#f44336',
        light: '#ffcdd2',
        dark: '#8b1a12'
      },
      disabled: {
        light: '#fafafa',
        main: '#f5f5f5'
      },
      default: {
        main: '#2196f3',
        light: '#64b5f6',
        dark: '#1f8de5'
      },
      attention: {
        main: '#FFF42B',
        light: '#fff5a4',
        dark: '#a59c10'
      },
      selected: {
        light: '#ee5b8b',
        dark: 'rgba(255,167,51,0.74)'
      },
      text: {
        light: 'black',
        dark: 'white'
      },
      type: darked ? 'dark' : 'light'
    },
    typography: {
      h1: {
        fontSize: '1.65rem',
        fontWeight: '700',
        paddingBottom: '14px',
        paddingLeft: '5px'
      },
      h2: {
        fontSize: '1rem',
        fontWeight: '500',
        paddingTop: '5px',
        paddingLeft: '5px',
        marginTop: '5px',
        marginBottom: '5px'
      },
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"'
      ].join(',')
    },
    iconOutlined: {}
  });
  const classes = useStyles();

  //Effects
  useEffect(() => {
    window.scrollTo(0, 0);
    if (idCuenta) dispatch(fetchTenant(idCuenta));

    return function cleanup() {
      tenantReset.resetAll(dispatch);
    };
  }, []);

  // let usuarioLogueado = this.props.api.usuarioLogueado;
  return (
    <MuiThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className={classes.root}>
          <main className={classes.principal}>
            <Switch>
              <Route
                path="/olvido/:token"
                component={RestablecerOlvideMiPassword}
              />
              <Route path="/olvido" component={SolicitudOlvideMiPassword} />
              <Route path="/login" component={Login} />
              <Route path="/signup" component={BriefSignUp} />
            </Switch>
          </main>
        </div>
        <MensajeError />
        <footer className={classes.footer}>
          <Box
            display={'flex'}
            flexDirection="column"
            gridGap="15px"
            alignItems="center"
          >
            <img
              style={{ width: '150px', marginRight: '10px' }}
              src={logo}
              alt=""
            />
            <Copyright />
          </Box>
        </footer>
      </ThemeProvider>
    </MuiThemeProvider>
  );
}
